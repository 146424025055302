import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { APPEND_TOKEN } from 'src/app/constants';
import { environment } from 'src/environments/environment';

interface OTPVerifyBody {
  phoneNo: string;
  otp: string;
}

interface RetailIDAMVerifyBody {
  authCode: string;
  clientId: string;
}

export interface Policy {
  data: {
    id: number;
    attributes: {
      policies: {
        id: number;
        content: string;
        title: string;
      };
    };
  }[];
}

export interface TnC {
  data: {
    id: number;
    attributes: {
      tnc: {
        id: number;
        content: string;
        title: string;
      };
    };
  }[];
}

export interface OTPSuccessServerResponse {
  success: boolean;
  identityId: string;
  isNewUser: boolean;
  accessToken: string;
  accessTokenExpiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  loginFailedReason: string;
  idamAccessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  constructor(private httpClient: HttpClient) {}

  login(value: string) {
    if (environment.byPassPhoneLogin) {
      return of({ success: true });
    }
    return this.httpClient.post(environment.apiHost + `/auth/phone/login`, {
      phoneNo: value,
      canSendToNewNumber: environment.enableRoneGateway,
    });
  }

  verifyOTP(body: OTPVerifyBody) {
    return this.httpClient.post<OTPSuccessServerResponse>(
      environment.apiHost + `/auth/phone/verify`,
      body
    );
  }

  getPrivacyPolicies() {
    return this.httpClient.get<Policy>(
      `${environment.cmsHost}/api/privacy-policies?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getTnC() {
    return this.httpClient.get<TnC>(
      `${environment.cmsHost}/api/tncs?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  verifyRetailIDAMToken(body: RetailIDAMVerifyBody) {
    return this.httpClient.post<OTPSuccessServerResponse>(
      environment.apiHost + `/auth/retail-idam/verify`,
      body
    );
  }
}
