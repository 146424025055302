import { Component, Input, OnInit } from '@angular/core';
import { NoResults } from 'src/app/views/partners/interfaces';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrl: './no-results.component.scss',
})
export class NoResultsComponent implements OnInit {
  @Input() noResults!: NoResults;
  constructor() {}
  ngOnInit(): void {}
}
