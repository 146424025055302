// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment-interface';

export const environment: IEnvironment = {
  production: false,
  apiHost: 'https://r-one-website-dev.api.engageapps.jio',
  jcnApi: 'https://sitofferapi.coupon.jio/offerstore',
  storeLocaterURL: 'https://storelocator.ril.com/Retail.aspx',
  cmsHost: 'https://r-one-website-dev.api.engageapps.jio/cms',
  enableRoneGateway: false,
  //For local development
  // clientIdForRetailIDAM:'2aface0a-afcb-49c2-ab50-be4e39c958a6',
  clientIdForRetailIDAM: 'bc845352-dfb2-4584-bac2-d3e1c1564d76',
  retailIDAMURL: 'https://account.cctz0.de',
  retailIDAMBackendURL: 'https://api.cctz0.de',
  jcnClientId: 'taara',
  byPassPhoneLogin: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
