<div class="offer-card" [ngStyle]="{
  'background-image': 'url(' + item.background + ')',
}"  (click)="goToOffer(item.id)">
    <div class="offer-header" *ngIf="item.expiry">Expires {{ item.expiry | date:'dd/MM/yyyy' }}</div>
    <div class="image-description">
      <div
        class="offer-content"
      >
        <div class="title">{{ item.title }}</div>
        <div class="offer-description">{{ item.description }}</div>
      </div>
    </div>
    <div class="offer-footer">
      <img [src]="item.storeImageURL" />
      {{ item.store }}
    </div>
  </div>

  