<!-- Header navigation -->
<header class="navbar-bg">
    <div class="nav-container">
        <nav class="navbar" role="navigation">
            <div class="burger-menu" id="burger-menu" (click)="handleHamBurger()" *ngIf="!isNativeApp">
                <img [ngStyle]="{'display' : isHamBurgerClicked ? 'none' : 'block'}"
                    src="assets/images/header/burger.svg" alt="Burger Menu" id="burger-icon">
                <img [ngStyle]="{'display' : isHamBurgerClicked ? 'block' : 'none'}"
                    src="assets/images/header/close.svg">
            </div>

            <div class="logo-and-balance">
                <div class="logo" (click)="handleLogo()">
                    <img [ngStyle]="{'display' : (isScrolled || isHamBurgerClicked) ? 'none' : 'block'}"
                        src="assets/images/header/logo.png">
                    <img [ngStyle]="{'display' : (isScrolled || isHamBurgerClicked) ? 'block' : 'none'}"
                        src="assets/images/header/logo-small.png">
                </div>

                <div class="balance-small" *ngIf="(isScrolled || isHamBurgerClicked) && isLoggedIn">
                    <div class="balance-circle">₹{{points.toString().split('.')[0] | number}}<span
                            class="paise">.{{(((points.toString() |
                            number:'1.2-2') || '00').split('.')[1]) }}</span>
                    </div>
                </div>
            </div>

            <ul class="nav-links">
                <li *ngIf="(isLoggedIn && showDashboard) && !isHomePage"><a [routerLink]="['/dashboard']"
                        routerLinkActive="active">Dashboard</a></li>
                <li *ngIf="!isLoggedIn || (isLoggedIn && isHomePage)">
                    <a [routerLink]="['/']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Home</a>
                </li>
                <li *ngIf="isLoggedIn"><a [routerLink]="['/transactions']" routerLinkActive="active">Activity</a>
                </li>

                <li><a [routerLink]="['/partners']" routerLinkActive="active">Partners</a></li>
                <li><a [routerLink]="['/offers']" routerLinkActive="active">Offers</a></li>
                <li><a (click)="openStoreLocator()">Store locator</a></li>
                <li><a [routerLink]="['/support']" routerLinkActive="active">Support</a></li>
            </ul>

            <ng-container *ngIf="isLoggedIn && !isHomePage">
                <div class="profile" [routerLink]="['/profile']" routerLinkActive="profile-active">
                    <div class="profile-link">
                        <div class="profile-circle">{{userName | uppercase}} </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="!isLoggedIn" class="btn btn__sign-in" (click)="signIn()">Sign in</div>
            <div *ngIf="isLoggedIn && isHomePage" class="btn btn__sign-in"
                (click)="handleNavigation('dashboard')">Dashboard</div>
        </nav>
    </div>
</header>

<!-- Overlay for nav drawer -->
<div class="nav-overlay" [ngStyle]="{'display' : isHamBurgerClicked ? 'block' : 'none'}" (click)="handleHamBurger()">
</div>

<!-- Add the drawer element -->
<div class="drawer" [ngClass]="{'drawer-open' : isHamBurgerClicked}">
    <ul>

        <li *ngIf="isLoggedIn" (click)="handleNavigation('dashboard')" class="selected"><a>Dashboard</a></li>

        <li *ngIf="!isLoggedIn" (click)="handleNavigation('home')" class="selected"><a>Home</a></li>

        <li *ngIf="isLoggedIn" (click)="handleNavigation('transactions')"><a>Activity</a></li>

        <li><a (click)="handleNavigation('partners')">Partners</a></li>
        <li><a (click)="handleNavigation('coupons')">Coupons</a></li>

        <li><a (click)="handleNavigation('store')">Store locator</a></li>
        <li><a (click)="handleNavigation('support')">Support</a></li>
        <!-- <li *ngIf="isLoggedIn" (click)="handleNavigation('profile')"><a>Profile</a></li> -->

        <section *ngIf="isLoggedIn && !isHomePage">
            <div class="nav-divider"></div>
            <li><a (click)="handleNavigation('home')">Homepage</a></li>
        </section>

    </ul>
</div>