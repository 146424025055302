import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPEND_TOKEN } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import {
  BigOfferFromServer,
  BigOffers,
  CarouselPartnersResponse,
  HomeCarouselPartnersResponse,
  TopPartnersResponse,
} from './interface';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  getCarouselPartners() {
    return this.httpClient.get<CarouselPartnersResponse>(
      `${environment.cmsHost}/api/partner-carousels?sort=id:ASC&populate=*,card.image,card.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getTopPartners() {
    return this.httpClient.get<TopPartnersResponse>(
      `${environment.cmsHost}/api/top-partner-offers?sort=id:ASC&populate=*,data.image,data.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getHomePartners() {
    return this.httpClient.get<HomeCarouselPartnersResponse>(
      `${environment.cmsHost}/api/home-page-partner-carousels?populate=*,data.image,data.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getBigOffers() {
    return this.httpClient.get<BigOffers>(
      `${environment.cmsHost}/api/offers?filters[isBigFiveOffer][$eq]=true&populate[partner][fields][1]=name&populate[partner][populate][icon][fields][0]=url&populate[image][fields][0]=url&sort=expiryDate:asc`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getBigOfferById(id: string) {
    return this.httpClient.get<BigOfferFromServer>(
      `${environment.cmsHost}/api/offers/${id}?populate[partner][fields][1]=name&populate[partner][populate][icon][fields][0]=url&populate[image][fields][0]=url`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }
}
