import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantInfo } from 'src/app/views/partners/interfaces';

@Component({
  selector: 'app-partners-card',
  templateUrl: './partners-card.component.html',
  styleUrl: './partners-card.component.scss',
})
export class PartnersCardComponent {
  constructor(private router: Router) {}
  @Input() partner!: MerchantInfo;
  @Input() fontSizeForText!: number;

  partnerDetails(partner: MerchantInfo) {
    this.router.navigate([`partners/${partner.name}`]).catch(() => {});
  }
}
