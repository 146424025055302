import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BigOffer } from 'src/app/views/main/dashboard/interface';

@Component({
  selector: 'app-big-offer-card',
  templateUrl: './big-offer-card.component.html',
  styleUrl: './big-offer-card.component.scss',
})
export class BigOfferCardComponent {
  @Input() item!: BigOffer;

  constructor(private router: Router) {}

  goToOffer(id: number) {
    this.router.navigate([`offers/big-offers/${id}`]).catch(() => {});
  }
}
