import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { OtpComponent } from './otp/otp.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ToastComponent } from './toast/toast.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SkeletonDirective } from './skeleton/skeleton.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { BigOfferCardComponent } from './big-offer-card/big-offer-card.component';
import { PartnersCardComponent } from './partners-card/partners-card.component';
import { NoResultsComponent } from './no-results/no-results.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule,
  ],
  declarations: [
    CarouselComponent,
    HeaderComponent,
    FooterComponent,
    TermsComponent,
    PrivacyComponent,
    OtpComponent,
    ErrorDialogComponent,
    ToastComponent,
    TooltipComponent,
    SkeletonDirective,
    TooltipDirective,
    BigOfferCardComponent,
    PartnersCardComponent,
    NoResultsComponent,
  ],
  exports: [
    CarouselComponent,
    CommonModule,
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    PrivacyComponent,
    TermsComponent,
    OtpComponent,
    ErrorDialogComponent,
    ToastComponent,
    MarkdownModule,
    TooltipComponent,
    SkeletonDirective,
    TooltipDirective,
    BigOfferCardComponent,
    PartnersCardComponent,
    NoResultsComponent,
  ],
})
export class SharedModule {}
